<template>
  <CyHeader v-if="organization">
    <template #avatar>
      <CyAvatar :item="organization"/>
    </template>
    <template #title>
      {{ organization.name }}
      <CyTooltip
        v-if="isRootOrg"
        right>
        <template #activator="{ on }">
          <CyTag
            small
            variant="default"
            class="ml-4"
            v-on="on">
            {{ $t('untranslated.root') }}
          </CyTag>
        </template>
        {{ $t('rootDescription') }}
      </CyTooltip>
    </template>
    <template #actions>
      <portal-target name="header-actions"/>
      <CyButton
        icon="vertical_split"
        variant="secondary"
        theme="primary"
        :to="{ name: 'organizations' }">
        {{ $t('seeAllOrganizations') }}
      </CyButton>
    </template>
    <template #metadata>
      <CyHeaderMetadata
        :label="$t('created', [$date.format($date.$getTimestamp(organization.created_at), 'MMM d, yyyy')])"/>
    </template>
  </CyHeader>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CyHeaderMetadata from '@/components/header/metadata.vue'
import CyHeader from '@/components/header.vue'
import CyTag from '@/components/tag.vue'

export default {
  name: 'CyHeaderVariantOrganization',
  components: {
    CyHeader,
    CyHeaderMetadata,
    CyTag,
  },
  computed: {
    ...mapState({
      organizations: (state) => state.organizations,
    }),
    ...mapGetters('organization', [
      'organization',
    ]),
    isRootOrg () {
      return this.organization.canonical === this.organizations[0].canonical
    },
  },
  i18n: {
    messages: {
      en: {
        private: 'Private',
        public: 'Public',
        trusted: 'Trusted',
        created: 'Created on {0}',
        seeAllOrganizations: 'See all organizations',
        rootDescription: 'A root organization is the top-level entity of the organizations hierarchy.',
      },
      es: {
        private: 'Privada',
        public: 'Pública',
        trusted: 'De confianza',
        created: 'Creado el {0}',
        seeAllOrganizations: 'Ver todas las organizaciones',
        rootDescription: 'Una organización raíz es la entidad de nivel superior de la jerarquía de organizaciones.',
      },
      fr: {
        private: 'Privée',
        public: 'Publique',
        trusted: 'De confiance',
        created: 'Créé le {0}',
        seeAllOrganizations: 'Voir toutes les organisations',
        rootDescription: `Une organisation racine est l'entité de niveau supérieur de la hiérarchie des organisations.`,
      },
    },
  },
}
</script>
